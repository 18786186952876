import axios from 'axios';
import React, { useContext, useState } from 'react'
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';
import config from '../../../config';
import Validator from '../../../Validator';

export default function LessonViewsRow({ data, refresh }) {

    const { userAuth } = useContext(auth)
    const [isUpdating, setIsUpdating] = useState(false)
    const [updateViewCountData, setUpdateViewCountData] = useState({
        lesson_view_id: data.id,
        watch_counte: data.watch_counte
    })

    const plus = () => {
        let data = { ...updateViewCountData }
        data.watch_counte += 1;
        setUpdateViewCountData(data)
    }

    const minuse = () => {
        let data = { ...updateViewCountData }
        if (data.watch_counte > 0) {
            data.watch_counte -= 1
        }
        setUpdateViewCountData(data)
    }

    const sendUpdateData = () => {
        setIsUpdating(true)

        if (updateViewCountData.watch_counte == data.watch_counte) {
            setIsUpdating(false)
            Swal.fire({
                text: 'لا يوجد اي تعديل',
                icon: 'warning'
            })
            return;
        }

        axios.put(`${config.basURL}dashboard/lesson-views`, updateViewCountData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setIsUpdating(false)
            refresh()
            Swal.fire({
                text: 'تم التعديل بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            setIsUpdating(false)
            console.log(err)
        })
    }

    return <>
        <tr style={{marginBottom: '15px'}}>
            <td> {data.lesson.name} </td>
            <td> {data.student.name} </td>
            <td> {data.student.user.username} </td>
            <td> {data.student.year ? data.student.year.name : 'لا يوجد'} </td>
            <td> {data.student.place  ? data.student.place.name : 'لا يوجد'} </td>
            <td className='view-count'>
                <input type="text" name='watch_counte' value={updateViewCountData.watch_counte} />
                <div className='btns'>
                    <button className='plus' onClick={plus}> <i className="fa-solid fa-chevron-up"></i> </button>
                    <button className='minuse' onClick={minuse}> <i className="fa-solid fa-chevron-down"></i> </button>
                </div>
            </td>
            <td>   {data.watch_time} دقائق </td>
            <td>
                <button className='update' onClick={sendUpdateData}>
                    {isUpdating ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'تعديل'}
                </button>
            </td>
        </tr>
    </>
}
