import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Swal from 'sweetalert2'
import Validator from '../../../Validator'

export default function AddNewStudent() {

    const { userAuth } = useContext(auth)
    const [newStudentData, setnewStudentData] = useState({})
    const [isLoading, setisLoading] = useState(false)

    const update = (e) => {
        let data = { ...newStudentData };
        data[e.target.name] = e.target.value;
        setnewStudentData(data)
    }

    const create = (e) => {
        e.preventDefault();
        setisLoading(true)

        let data = { ...newStudentData },
            validation = new Validator,
            newData = {}

        validation.setKey('code').setValue(data.code).required()

        if (Object.keys(data).length == 0 || validation.haveError == true) {
            Swal.fire({
                text: ' اكتب الكود الاول ',
                icon: 'warning'
            })
            setisLoading(false)
            return;
        }

        newData = {
            username: data.code,
            password: data.code,
            by_code: true ,
            code: data.code ,
            place_id: 2 ,
            type_education: 1
        }

        axios.post(`${config.basURL}student/student-sign-up`, newData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisLoading(false)

            if (res.data.user_errors) {
                let text = Object.values(res.data.user_errors).join('\\n')

                Swal.fire({
                    text: text,
                    icon: 'error'
                })
            } else {
                Swal.fire({
                    text: ' تم الاضافه بنجاح ',
                    icon: 'success'
                })
            }


        }).catch(err => {
            setisLoading(false)
            console.log(err)
        })
    }

    return <>
        <form onSubmit={create} className='search-bar dashboard-student-search'>
            <input type="text" name='code' placeholder=' ادخل الكود ' onChange={update} />
            <button> {
                isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'انشاء طالب سنتر '
            } </button>
        </form>
    </>
}
